var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"page-title-box"},[_c('h4',[_vm._v("Conversas")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"email-leftbar card"},[_c('router-link',{staticClass:"btn btn-danger btn-block",attrs:{"to":{ name: 'createConversation' }}},[_c('i',{staticClass:"mdi mdi-pencil"}),_vm._v(" Nova conversa ")]),_c('div',{staticClass:"mail-list mt-4"},[_c('router-link',{class:{
              active: !_vm.$route.query.filter || _vm.$route.query.filter === 'all',
            },attrs:{"to":{ name: 'conversations', query: { filter: 'all' } }}},[_vm._v(" Todas ")]),_c('router-link',{class:{
              active: _vm.$route.query.filter === 'open',
            },attrs:{"to":{ name: 'conversations', query: { filter: 'open' } }}},[_vm._v(" Abertas ")]),_c('router-link',{class:{
              active: _vm.$route.query.filter === 'closed',
            },attrs:{"to":{ name: 'conversations', query: { filter: 'closed' } }}},[_vm._v(" Dúvidas solucionadas ")])],1)],1),_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }