<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4>Conversas</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="email-leftbar card">
          <router-link
            :to="{ name: 'createConversation' }"
            class="btn btn-danger btn-block"
          >
            <i class="mdi mdi-pencil"></i>
            Nova conversa
          </router-link>
          <div class="mail-list mt-4">
            <router-link
              :to="{ name: 'conversations', query: { filter: 'all' } }"
              :class="{
                active: !$route.query.filter || $route.query.filter === 'all',
              }"
            >
              Todas
            </router-link>
            <router-link
              :to="{ name: 'conversations', query: { filter: 'open' } }"
              :class="{
                active: $route.query.filter === 'open',
              }"
            >
              Abertas
            </router-link>
            <router-link
              :to="{ name: 'conversations', query: { filter: 'closed' } }"
              :class="{
                active: $route.query.filter === 'closed',
              }"
            >
              Dúvidas solucionadas
            </router-link>
          </div>
        </div>
        <router-view />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Conversas",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
  },
};
</script>
